import { dwhBaseUrl } from "Configs/config"
import { DataWareHouseInput, DataWareHouseInputParsed } from "Utils/api/datawarehouse/types"
import { urlParamsFormat } from "Utils/url"
import { match } from "ts-pattern"

export const parseDatawarehouseInput = (
	input: DataWareHouseInput,
	siteName: string | undefined
): DataWareHouseInputParsed => {
	const client = siteName === "default" ? "bir" : siteName
	return match(input)
		.with({ endpoint: "terminal/customers" }, data => ({
			...data,
			filters: {
				includeWasteTypes: true,
				sortOrder: "asc" as const,
				...data.filters,
			},
			path: `/api/clients/${client}/terminals/${data.pathParams.id}/customers`,
		}))
		.with({ endpoint: "terminal" }, data => ({
			...data,
			path: `/api/clients/${client}/terminals/${data.terminalId}`,
		}))
		.with({ endpoint: "terminal-aggregates" }, data => ({
			...data,
			path: `/api/clients/${client}/terminal-aggregates`,
		}))
		.with({ endpoint: "terminal/periods" }, data => ({
			...data,
			path: `/api/clients/${client}/terminals/${data.terminalId}/periods`,
		}))
		.with({ endpoint: "terminal-aggregates-periods" }, data => ({
			...data,
			path: `/api/clients/${client}/terminal-aggregates-periods`,
		}))
		.exhaustive()
}

export const getDatawarehousePayload = (input: DataWareHouseInputParsed) => {
	const { endpoint, path, filters } = input
	const queryParams = filters ? "?" + urlParamsFormat(filters) : ""

	const cacheKey = [path, queryParams]
	const url = new URL(`${path}${queryParams}`, dwhBaseUrl).toString()

	return { url, cacheKey, endpoint }
}
