import { sum } from "lodash"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { TenantType } from "."
import { getSortedWasteTypes } from "./exportExcel"
import { useCommonEntitiesStore } from "States/commonEntities"
import { formatNumberForTable } from "Utils/formatFunctions"
import { useConfigService } from "pages/configuration/useConfigService"

export const StickyFooterComponent = ({
	filteredWasteTypes,
	tenants,
	currentTerminalName,
	showCustomerUnits,
	showCO2,
}: {
	filteredWasteTypes: string[]
	tenants: TenantType[]
	currentTerminalName: string
	showCustomerUnits: boolean
	showCO2: boolean
}) => {
	const { t } = useTrans()
	const { wasteTypes } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()

	const allTypesSums = getSortedWasteTypes(
		filteredWasteTypes,
		wasteTypeConfig,
		wasteTypes || []
	).map(code => sum(tenants.map(el => el[code] || 0)))

	const allTypesTotal = sum(allTypesSums)

	const totalRow = [...allTypesSums, allTypesTotal]

	return (
		<tfoot
			className="sticky bottom-0 h-12 font-medium text-sm py-3 bg-grey1 before:absolute before:top-[-1px] before:left-0 before:w-full before:border-t before:border-black
                 after:absolute after:bottom-[1px] after:left-0 after:w-full after:border-b after:border-black z-[9]"
		>
			<tr>
				<td>{/* Checkbox */}</td>
				<td className="pl-2 sticky left-0 bg-grey1 h-12 flex items-center">
					{t(showCO2 ? "genericLabels:totalCo2" : "genericLabels:totalKg", { currentTerminalName })}
				</td>
				{showCustomerUnits && <td>{/* Customer unit row */}</td>}
				{totalRow.map((val, i, arr) => (
					<td
						key={i}
						className={twMerge(
							" justify-end text-end last:sticky last:right-0 last:bg-grey1 h-full bg-grey1 pr-8",
							i === arr.length - 1 && "pr-4"
						)}
					>
						{val ? formatNumberForTable(val) : ""}
					</td>
				))}
			</tr>
		</tfoot>
	)
}
