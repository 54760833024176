import { useSelectedSite } from "api/hooks/useSelectedSite"
import {
	useNavigate as RRDUseNavigate,
	NavigateFunction,
	NavigateOptions,
	To,
} from "react-router-dom"

export function useNavigate(): NavigateFunction {
	const navigate = RRDUseNavigate()
	const { selectedSite } = useSelectedSite()

	const siteName = selectedSite?.siteName

	if (siteName) {
		return (to: To | number, options?: NavigateOptions) => {
			switch (typeof to) {
				case "number":
					navigate(to)
					break
				case "string":
					navigate(to.startsWith("/") ? `/${siteName}${to}` : to, options)
					break
				default:
					navigate({ pathname: `/${siteName}${to.pathname}`, ...to }, options)
			}
		}
	}
	return navigate
}
