import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"
import { useConfig } from "api/hooks/useConfig"
import FormInput from "components/Form/FormInput"
import SearchInput from "components/Form/SearchInput"
import { IInputOption } from "components/GenericComponents/input"
import { orderBy, groupBy, uniqBy } from "lodash"
import { useConfigService } from "pages/configuration/useConfigService"
import { BaseSyntheticEvent, FC, useMemo, useRef } from "react"
import { UseFormReturn } from "react-hook-form"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { isValidWasteType } from "Utils/isValidWasteType"

export type ContainerFormData = {
	wasteTypeClassificationSystem: string
	wasteCode: string
	containerName: string
	status?: string
}

export const ContainerForm: FC<{
	formHandler: UseFormReturn<ContainerFormData>
	onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
	isEdit?: boolean
}> = ({ formHandler, onSubmit, isEdit = false }) => {
	const { t } = useTrans()
	const parentElementRef = useRef(null)
	const { isMWM } = useConfig()

	const {
		register,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = formHandler

	const { wasteTypes, wasteTypeClassificationSystems } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()
	const { wasteTypeClassificationSystem, wasteCode, status, containerName } = getValues()

	const wasteTypeClassificationSystemsOptions: IInputOption[] = useMemo(
		() =>
			wasteTypeClassificationSystems?.map(({ id, name }) => ({ option: name, value: id })) || [],
		[wasteTypeClassificationSystems]
	)

	const wasteTypesWithWasteCodeOption: IInputOption[] = useMemo(() => {
		if (!wasteTypes?.length) return []

		const unOrdered = wasteTypeClassificationSystem
			? groupBy(wasteTypes, "classificationSystem.id")[wasteTypeClassificationSystem]
			: wasteTypes

		// Map to options
		const options = unOrdered.map(({ id, name }) => ({
			option: `${wasteTypeConfig.find(wtc => wtc.wasteTypeCode === id)?.name || name} (${id})`,
			value: id,
		}))

		// Add option for the current value in case it's a custom value
		const withExistingWasteCode = uniqBy(
			wasteCode ? options.concat([{ option: wasteCode, value: wasteCode }]) : options,
			"value"
		)

		return orderBy(withExistingWasteCode, "option")
	}, [wasteTypes, wasteTypeClassificationSystem, wasteCode, wasteTypeConfig])

	const onWasteCodeChange = ({ value }: IInputOption) => {
		// Update name when wasteCode changes
		setValue("containerName", wasteTypes?.find(({ id }) => id === value)?.name || "")
	}

	const stationOptions = useMemo(
		() =>
			POINT_STATUSES.map(value => ({
				option: `status:${value}`,
				value,
				translate: true,
			})),
		[]
	)

	const wasteTypeWarning = useMemo(() => {
		if (!wasteTypes || !wasteCode) return undefined
		if (!isValidWasteType(wasteTypes, wasteCode)) {
			return t("hints:invalidWasteTypeTooltip")
		}
		return undefined
	}, [wasteTypes, wasteCode, t])

	return (
		<form {...{ onSubmit }}>
			<div className="mb-6 flex-col gap-5 min-h-[350px]" ref={parentElementRef}>
				<SearchInput
					label={t("formLabels:wasteTypeClassificationSystem")}
					placeholder={t("actions:selectProperty", {
						property: t("formLabels:wasteTypeClassificationSystem").toLowerCase(),
					})}
					name={"wasteTypeClassificationSystem"}
					data={wasteTypeClassificationSystemsOptions}
					errorObj={errors?.wasteTypeClassificationSystem}
					initialValue={wasteTypeClassificationSystem || undefined}
					noInitialOption={!wasteTypeClassificationSystem}
					onChange={() => {
						setValue("wasteCode", "")
					}}
					{...{ control, setValue }}
					required={!isEdit}
					tooltip={{
						text: t("hints:addOrEditWasteTypeClassificationSystemDescription"),
						id: "addOrEditWasteTypeClassificationSystemDescription",
					}}
				/>
				<SearchInput
					label={t("formLabels:wasteTypeWithCode")}
					placeholder={t("actions:selectProperty", {
						property: t("genericLabels:wasteType").toLowerCase(),
					})}
					name={"wasteCode"}
					disabled={!wasteTypeClassificationSystem}
					inputClassName="disabled:border-gray-300 disabled:bg-gray-100"
					data={wasteTypesWithWasteCodeOption}
					errorObj={errors?.wasteCode}
					initialValue={wasteCode || undefined}
					noInitialOption={!wasteCode}
					{...{ control, setValue, parentElementRef }}
					required
					onChange={onWasteCodeChange}
					ignoreOnRenderChange
					warning={wasteTypeWarning}
					tooltip={{
						text: t("hints:addOrEditWasteTypeWithCodeDescription"),
						id: "addOrEditWasteTypeWithCodeDescription",
					}}
				/>
				<FormInput
					name="containerName"
					label={t("formLabels:nameOfContainer")}
					register={register}
					error={errors?.containerName || (isEdit && !containerName && { type: "required" })}
					required
					showErrorInTooltip={false}
				/>
				{isMWM && (
					<SearchInput
						label={t("formLabels:status")}
						name={"status"}
						data={stationOptions}
						initialValue={status}
						{...{ control, setValue, parentElementRef }}
					/>
				)}
			</div>
		</form>
	)
}
