import { LoadingIndicator } from "Icons/loadingIndicator"
import { useTerminalsStateRE } from "States/Terminals"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { EmptyView } from "components/EmptyView/EmptyView"
import Piechart from "components/Piechart"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import {
	createTimeFilters,
	getTenantCategoryOptions,
} from "components/pageCards/filterSort/filterCreators"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { useEffect, useMemo, useState } from "react"
import { useTrans } from "translations"
import { getWasteTypesOptions } from "../functions/filters"
import { pieWasteContribution } from "../functions/pieChartData"
import { FilterTimeFrameValue } from "components/pageCards/filterSort/filterCreators"
import { OPTION_ALL, hasNonAllOptionActive } from "components/pageCards/filterSort/constants"
import { useConfigService } from "pages/configuration/useConfigService"
import { usePdfPreview } from "pages/home/pdfPreview"
import { useHomeLoadingState } from "pages/home/loadingState"

type WasteContributionFilters = "period" | "tenantCategory" | "wasteType"

export const PieWasteContribution = () => {
	const { t } = useTrans()

	const [
		{ period: [selectedPeriod] = [], tenantCategory, wasteType: [selectedWasteType] = [] },
		setFilterState,
	] = useState<ActiveOptions<WasteContributionFilters>>({} as any)
	const { wasteTypes, tenantCategories } = useCommonEntitiesStore()
	const { currentTerminal } = useTerminalsStateRE()
	const { wasteTypeConfig } = useConfigService()
	const { pdfPreviewOpen } = usePdfPreview()
	const { setWasteContributionLoading } = useHomeLoadingState()

	const { startTimeISO, endTimeISO } = useMemo(() => {
		return getTimeFrame(
			(selectedPeriod?.value as FilterTimeFrameValue) ?? FilterTimeFrameValue.LAST_30_DAYS
		)
	}, [selectedPeriod])

	const {
		data: currentPeriod,
		isError,
		isLoading,
	} = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: {
			startTimeISO,
			endTimeISO,
			commercialCustomerCategory: hasNonAllOptionActive(tenantCategory)
				? tenantCategory[0].value
				: undefined,
		},
	})

	useEffect(() => {
		setWasteContributionLoading(isLoading)
	}, [setWasteContributionLoading, isLoading])

	const pieFilters: FilterSort[] = [
		createTimeFilters({ defaultValue: FilterTimeFrameValue.LAST_30_DAYS }, [
			FilterTimeFrameValue.LAST_30_DAYS,
			FilterTimeFrameValue.LAST_MONTH,
			FilterTimeFrameValue.YEAR_TO_DATE,
			FilterTimeFrameValue.LAST_6_FULL_MONTHS,
			FilterTimeFrameValue.LAST_FULL_YEAR,
		]),
		{
			id: "tenantCategory",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: getTenantCategoryOptions(tenantCategories),
			title: "statisticsLabels:tenantCategory",
			defaultValue: OPTION_ALL,
		},
		{
			id: "wasteType",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: getWasteTypesOptions(currentPeriod, wasteTypes, wasteTypeConfig),
			title: "entities:wasteTypes",
			defaultValue: "all",
		},
	]

	const pieWasteDistributionData = useMemo(
		() =>
			currentPeriod
				? pieWasteContribution(
						currentPeriod,
						selectedWasteType?.value,
						t("statisticsLabels:otherTenants")
					)
				: [],
		[currentPeriod, t, selectedWasteType]
	)

	const chart = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		if (!pieWasteDistributionData.length) {
			return <EmptyView type="noDataForInterval" className="min-h-[300px]" />
		}

		return (
			<Piechart
				data={pieWasteDistributionData}
				filterLabel={t("hints:clickToSelectOrUnselectTenants")}
			/>
		)
	}, [isError, isLoading, pieWasteDistributionData, t])

	return (
		<Card
			title={"statisticsLabels:wasteContributions"}
			filters={pieFilters}
			onFilterOptionChange={setFilterState}
			filterLocation={CardComponentFilterLocation.RIGHT}
			alwaysExpanded={pdfPreviewOpen}
		>
			{chart}
		</Card>
	)
}
