import { Page } from "components/Page"
import {
	TimeFilterCustomDate,
	getCustomTimeFrame,
	getTimeFrame,
} from "components/pageCards/filterSort/filterTimeFrame"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsStateRE } from "States/Terminals"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { EmptyView } from "components/EmptyView/EmptyView"
import { Header, TableWithSelect } from "components/TableWithSelect"
import { sum, uniq } from "lodash"
import { exportCsv } from "./exportCsv"
import { exportExcel, getSortedWasteTypes } from "./exportExcel"
import { SortingState } from "@tanstack/react-table"
import { StickyFooterComponent } from "./stickyFooterComponent"
import { getWasteTypeName } from "Utils/commonExportFunctions"
import { formatWeight } from "Utils/formatFunctions"
import { weightRender } from "components/TableWithSelect/utils/cellRenderFunctions"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import {
	FilterTimeFrameValue,
	createFractionFilter,
	createTimeFilters,
} from "components/pageCards/filterSort/filterCreators"
import { Card } from "components/pageCards/card"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"
import { useConfigService } from "pages/configuration/useConfigService"
import { Customer } from "Utils/api/datawarehouse/responseTypes"
import { useTrans } from "translations"
import { getFlatDownstreamData } from "Utils/downstreamHandlingUtils"
import Segmented from "rc-segmented"
import "rc-segmented/assets/index.css"
import { Toggle } from "components/Toggle"
import InfoPopup from "components/InfoPopup/InfoPopup"

const CO2_VALUE = "CO2"
const KG_VALUE = "KG"

export type TenantType = {
	id: string
	name: string
	total: number | string
	unitName: string
	parentId: string
	[k: string]: string | number
}

type ReportingFilters = "timeframe" | "fractionId"
const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

export const Reporting: React.FC = () => {
	const [
		{ timeframe: [selectedTimeframe] = [], fractionId: [selectedFractionId] = [] },
		setActiveOptions,
	] = useState<ActiveOptions<ReportingFilters>>({} as any)

	const { currentTerminal } = useTerminalsStateRE()
	const { wasteTypes: sanityWasteTypes, downstreamHandling } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()
	const { t } = useTrans()
	const [selectedIds, setSelectedIds] = useState<string[]>([])
	const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }])
	const [showCO2, setShowCO2] = useState<boolean>(false)
	const [showCustomerUnits, setShowCustomerUnits] = useState<boolean>(false)

	const filterTimeframe = useMemo(() => {
		if (selectedTimeframe?.option === "interval.custom") {
			return getCustomTimeFrame(selectedTimeframe.value as TimeFilterCustomDate)
		} else {
			return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultPeriod)
		}
	}, [selectedTimeframe])

	const {
		data,
		isError: isErrorTenants,
		isLoading: isLoadingTenants,
	} = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: filterTimeframe,
	})

	const { data: sinceBeginningData, isLoading: isSinceBeginningDataLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: { ...getTimeFrame(FilterTimeFrameValue.SINCE_BEGINNING) },
	})

	const getAllWasteTypesFromCustomers = useCallback((customers?: Customer[]) => {
		const wasteTypes: string[] = []

		customers?.forEach(tenant => {
			const individualWasteTypes = tenant.wasteTypes.map(wt => wt.code)

			wasteTypes.push(...individualWasteTypes)
		})

		return uniq(wasteTypes)
	}, [])

	const allWasteTypes = useMemo(
		() => getAllWasteTypesFromCustomers(sinceBeginningData?.customers),
		[sinceBeginningData, getAllWasteTypesFromCustomers]
	)

	const wasteTypes = useMemo(() => {
		const filteredWasteTypes = allWasteTypes.filter(wt => !!getWasteTypeName(sanityWasteTypes!, wt))

		if (selectedFractionId?.value !== OPTION_ALL && selectedFractionId) {
			return filteredWasteTypes.filter(wt => wt === selectedFractionId.value)
		}
		return filteredWasteTypes
	}, [allWasteTypes, selectedFractionId, sanityWasteTypes])

	useEffect(() => {
		setSelectedIds([])
	}, [data])

	const getNumberToShow = useCallback(
		({ wasteCode, amount }: { wasteCode: string; amount: number }) => {
			const wasteConfig = wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteCode)

			if (!showCO2) {
				return amount
			}

			return !wasteConfig?.co2factor ? "" : wasteConfig.co2factor * amount
		},
		[showCO2, wasteTypeConfig]
	)

	const tenantsReportData = useMemo(() => {
		if (!data || !sanityWasteTypes) {
			return []
		}

		const mapTenant = (tenant: Customer & { unitName?: string; parentId?: string }) => {
			// Individual waste types data
			const individualWasteTypes = tenant.wasteTypes.map(wt => ({
				amount: wt.weight.quantity,
				wasteCode: wt.code,
			}))

			const tenantData: TenantType = {
				id: tenant.customerId,
				name: tenant.customerName,
				parentId: tenant.parentId || "",
				unitName: tenant.unitName || "",
				total: formatWeight(
					sum(
						individualWasteTypes
							.filter(wt => wasteTypes.includes(wt.wasteCode))
							.map(el => getNumberToShow(el))
					)
				),
			}

			wasteTypes.forEach(code => {
				const wt = individualWasteTypes.find(el => el.wasteCode === code)

				if (wt) {
					tenantData[wt.wasteCode] = formatWeight(getNumberToShow(wt))
				} else {
					tenantData[code] = ""
				}
			})

			return tenantData
		}

		let tenants = data.customers.filter(t => !!t.customerId)

		if (showCustomerUnits) {
			tenants = tenants.flatMap(customer => {
				if (customer.children && customer.children.length > 0) {
					return customer.children.flatMap(unit => ({
						...unit,
						customerName: customer.customerName,
						parentId: customer.customerId,
						unitName: unit.customerName,
						children: undefined,
					}))
				}
				return { ...customer, children: undefined }
			})
		}
		return tenants.map(mapTenant)
	}, [data, sanityWasteTypes, showCustomerUnits, wasteTypes, getNumberToShow])

	const filters: FilterSort[] = useMemo(
		() => [
			createTimeFilters(
				{
					id: "timeframe",
					menuType: FilterSortMenuType.TimeFrame,
					defaultValue: defaultPeriod,
				},
				[
					FilterTimeFrameValue.LAST_30_DAYS,
					FilterTimeFrameValue.LAST_MONTH,
					FilterTimeFrameValue.YEAR_TO_DATE,
					FilterTimeFrameValue.LAST_YEAR,
					FilterTimeFrameValue.LAST_6_FULL_MONTHS,
					FilterTimeFrameValue.LAST_FULL_YEAR,
					FilterTimeFrameValue.SINCE_BEGINNING,
				]
			),
			createFractionFilter(
				allWasteTypes.map(wt => ({ code: wt })),
				sanityWasteTypes,
				wasteTypeConfig
			),
		],
		[allWasteTypes, sanityWasteTypes, wasteTypeConfig]
	)

	const showError = useMemo(
		() => !isLoadingTenants && isErrorTenants,
		[isLoadingTenants, isErrorTenants]
	)
	const showEmpty = useMemo(
		() => !isLoadingTenants && !isErrorTenants && !tenantsReportData.length,
		[isLoadingTenants, isErrorTenants, tenantsReportData]
	)

	const showTable = useMemo(
		() => isLoadingTenants || (tenantsReportData.length! > 0 && !showError),
		[isLoadingTenants, tenantsReportData, showError]
	)

	const hasCustomerUnits = useMemo(
		() => !!data?.customers.some(c => c.children && c.children.length > 0),
		[data]
	)

	const flatDownstreamData = useMemo(
		() => getFlatDownstreamData(downstreamHandling || []),
		[downstreamHandling]
	)

	const tableHeaders: Header[] = useMemo(
		() =>
			[
				{
					key: "name",
					title: "entities:tenant",
					stickyLeft: true,
					sortable: true,
				},
				showCustomerUnits
					? {
							key: "unitName",
							title: "entities:customerUnit",
							stickyLeft: true,
							sortable: true,
						}
					: undefined,
				...wasteTypes
					.map(wt => {
						const wtConfig = wasteTypeConfig.find(el => el.wasteTypeCode === wt)

						const title = `${wtConfig?.name || getWasteTypeName(sanityWasteTypes!, wt)} (${wt})`

						const downstream = flatDownstreamData[wtConfig?.downstreamHandlingId!]

						return {
							key: wt,
							title,
							rightAlign: true,
							sortable: true,
							renderFunction: weightRender,
							extraPopupText: downstream?.name,
						}
					})
					.sort((a, b) => sortAlphabeticallyByProperty(a, b, "title")),
				{
					key: "total",
					title: showCO2 ? "genericLabels:totalPerTenantCo2" : "genericLabels:totalPerTenantKg",
					rightAlign: true,
					stickyRight: true,
					sortable: true,
					renderFunction: weightRender,
				},
			].filter(Boolean) as Header[],
		[showCustomerUnits, wasteTypes, showCO2, wasteTypeConfig, sanityWasteTypes, flatDownstreamData]
	)

	const onRowClick = useCallback(
		(el: TenantType) => {
			if (selectedIds.includes(el.id)) {
				setSelectedIds(selectedIds.filter(id => id !== el.id))
			} else {
				setSelectedIds([...selectedIds, el.id])
			}
		},
		[selectedIds]
	)

	const getStickyFooterComponent = useCallback(
		(ref: HTMLTableElement) => (
			<StickyFooterComponent
				filteredWasteTypes={wasteTypes}
				tenants={tenantsReportData}
				currentTerminalName={currentTerminal.name!}
				showCustomerUnits={showCustomerUnits}
				showCO2={showCO2}
			/>
		),
		[currentTerminal, wasteTypes, tenantsReportData, showCustomerUnits, showCO2]
	)

	const getSortedData = useCallback(() => {
		const filter = sorting[0]

		const selectedData = selectedIds.length
			? (tenantsReportData || []).filter((el: any) => selectedIds.includes(el.id))
			: tenantsReportData || []

		const sortedData = selectedData.sort((a, b) => {
			const compareResult = a[filter.id].toString().localeCompare(b[filter.id].toString())

			return filter.desc ? -compareResult : compareResult
		})

		return sortedData
	}, [sorting, tenantsReportData, selectedIds])

	const exportExcelFunction = useCallback(() => {
		const sortedData = getSortedData()
		exportExcel({
			tenants: sortedData,
			wasteTypes: getSortedWasteTypes(wasteTypes, wasteTypeConfig, sanityWasteTypes || []),
			nrSelected: selectedIds.length,
			nrTotal: tenantsReportData.length,
			timeframe: filterTimeframe,
			sanityWasteTypes: sanityWasteTypes || [],
			wasteTypeConfig,
			showCustomerUnits,
			flatDownstreamData,
			showCO2,
		})
	}, [
		wasteTypes,
		selectedIds,
		filterTimeframe,
		sanityWasteTypes,
		getSortedData,
		tenantsReportData,
		wasteTypeConfig,
		showCustomerUnits,
		showCO2,
		flatDownstreamData,
	])
	const exportCsvFunction = useCallback(() => {
		const sortedData = getSortedData()

		exportCsv({
			tenants: sortedData,
			tenantDetails: data?.customers ?? [],
			wasteTypes: getSortedWasteTypes(wasteTypes, wasteTypeConfig, sanityWasteTypes || []),
			timeframe: filterTimeframe,
			sanityWasteTypes: sanityWasteTypes || [],
			wasteTypeConfig,
			showCustomerUnits,
			flatDownstreamData,
		})
	}, [
		data,
		filterTimeframe,
		wasteTypes,
		sanityWasteTypes,
		getSortedData,
		wasteTypeConfig,
		showCustomerUnits,
		flatDownstreamData,
	])

	return (
		<Page title="sidebarLabels:reporting" fullHeight>
			<Card
				overflow="auto"
				filters={filters}
				onFilterOptionChange={setActiveOptions}
				hasYContentPadding={false}
				hasXContentPadding={false}
				exportButton={{
					nrSelected: selectedIds.length,
					areAllSelected: tenantsReportData.length === selectedIds.length,
					exportExcelFunction,
					exportCsvFunction,
					isLoading: isSinceBeginningDataLoading,
				}}
				alwaysExpanded
				toggleView={
					<div className="flex gap-x-4 text-sm ml-2">
						<div className="flex items-center">
							<Segmented
								options={[
									{
										label: t("genericLabels:co2Label"),
										value: CO2_VALUE,
										className: "h-7",
									},
									{
										label: t("genericLabels:kgLabel"),
										value: KG_VALUE,
										className: "h-7",
									},
								]}
								onChange={(value: string) => setShowCO2(value === CO2_VALUE)}
								value={showCO2 ? CO2_VALUE : KG_VALUE}
								style={{ backgroundColor: "#F7F2F2" }}
							/>
							<InfoPopup
								id="co2-kg-toggle"
								size={15}
								className="ml-1 inline-block align-baseline"
								width="350px"
								text={t("hints:co2Hint")}
							/>
						</div>

						{hasCustomerUnits && (
							<div className="flex items-center">
								<Toggle
									label={t("filterLabels:toggleZonesReporting")}
									checked={showCustomerUnits}
									onChange={() => {
										setSelectedIds([])
										setShowCustomerUnits(prev => !prev)
									}}
									labelClassName="mx-1"
								/>
								<InfoPopup
									id="toggleZonesReporting"
									text={t("hints:toggleZonesReporting")}
									width="300px"
									size={15}
								/>
							</div>
						)}
					</div>
				}
			>
				{showError && <EmptyView type="unableToFetchEvents" />}
				{showEmpty && <EmptyView type="noEvents" />}
				{showTable && (
					<>
						<TableWithSelect
							selectedIds={selectedIds}
							setSelectedIds={setSelectedIds}
							headers={tableHeaders}
							defaultSortingHeader={{ id: "name", desc: false }}
							updateSorting={setSorting}
							labelSingle="entities:event"
							labelMultiple="sidebarLabels:events"
							showFooter={false}
							stickyCheckbox
							{...{
								data: tenantsReportData,
								isLoading: isLoadingTenants,
								onRowClick,
								getStickyFooterComponent,
							}}
						/>
					</>
				)}
			</Card>
		</Page>
	)
}

export default Reporting
