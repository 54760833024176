import { AlertLine } from "components/AlertLine/AlertLine"
import { Button } from "components/button/index"
import { FC, useEffect, useState } from "react"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsStateRE } from "States/Terminals"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/coreApi/customers/types"
import { useCommonEntitiesStore } from "States/commonEntities"
import { StyledSelect } from "components/StyledSelect"

interface CommunicationSectionProps {
	customer: CustomerWithDetailsAndCode
	type: "mobile" | "tablet"
}

export const CommunicationSection: FC<CommunicationSectionProps> = ({ customer, type }) => {
	const { t, language } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()
	const { languages } = useCommonEntitiesStore()

	const { currentTerminal } = useTerminalsStateRE()
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [pickedLanguage, setPickedLanguage] = useState(language)

	const {
		mutate: sendWelcomeEmail,
		data: result,
		isLoading,
		isSuccess,
		isError: isMutationError,
	} = trpc.notifications.sendWelcomeEmail.useMutation()

	useEffect(() => {
		setIsEmailSent(false)
	}, [customer])

	const onButtonClick = () => {
		sendWelcomeEmail({
			customerId: customer.id,
			terminalId: currentTerminal.id,
			type,
			language: pickedLanguage,
		})
		setIsEmailSent(true)
	}

	const close = () => setIsEmailSent(false)

	const isError = isEmailSent && (isMutationError || (isSuccess && result?.status > 0))

	useEffect(() => {
		if (isEmailSent && !isLoading && !isError) {
			setGlobalAlert({
				type: "success",
				message: "hints:welcomeEmailSent",
			})
		}
	}, [isLoading, isError, setGlobalAlert, isEmailSent])

	const languageOptions = languages?.map(lang => ({
		label: lang.name,
		value: lang.code,
	}))

	return (
		<div className="mb-6">
			<p className="font-dmSans text-base text-black font-medium">
				{t(`hints:onboardingEmailTitle_${type}`)}
			</p>
			<p className="mb-5 font-dmSans text-sm text-black">
				{t(`hints:onboardingEmailDescription_${type}`)}
			</p>
			{isError ? (
				<div className="w-full font-bold text-carrotRed flex h-[40px]">
					<AlertLine type="error" message="errors:errorSendingEmail" close={close} isStatic />
				</div>
			) : (
				<div className="flex">
					{!customer.companyCode && (
						<div className="w-full font-bold text-carrotRed mb-4">
							<AlertLine
								type="error"
								message="errors:companyCodeMissing"
								canClose={false}
								isStatic
							/>
						</div>
					)}
					<div className="w-2/3">
						<label className="select-none text-sm font-medium">
							{t("configLabels:selectEmailLanguage")}
						</label>
						<StyledSelect
							className="mt-1 mb-4"
							options={languageOptions}
							value={languageOptions?.find(el => el.value === pickedLanguage)}
							onChange={option => setPickedLanguage(option.value)}
						/>
					</div>
					<Button
						loading={isLoading}
						disabled={!customer.companyCode}
						label={"actions:sendWelcomeEmail"}
						color="secondary"
						className="float-right ml-3 mt-7"
						onClick={onButtonClick}
					/>
				</div>
			)}
		</div>
	)
}
