import { ColumnDef, Row, Table } from "@tanstack/react-table"
import { tableSortFn } from "Utils/sorting"
import { get } from "lodash"
import { TableCellRenderFunctionsType, cellRenderFunctions } from "./cellRenderFunctions"
import { MouseEvent } from "react"
import { TableCheckbox } from "components/pageCards/dataTable/components/checkbox"
import { TOptions } from "i18next"
import { Header } from ".."
import { TableActions } from "../components/TableActions"
import { TableExpand } from "components/pageCards/dataTable/components/expand"

const getExpandColumn = () => ({
	id: "expand",
	size: 30,
	header: ({ table }: { table: Table<any> }) => (
		<TableExpand
			isHeader
			onToggleExpanded={() => table.toggleAllRowsExpanded()}
			isExpanded={table.getIsAllRowsExpanded()}
		/>
	),
	cell: ({ row }: { row: Row<any> }) =>
		row.getCanExpand() && (
			<TableExpand
				onToggleExpanded={row.getToggleExpandedHandler()}
				isExpanded={row.getIsExpanded()}
			/>
		),
})

const getCheckboxColumn = ({
	selectedIds,
	setSelectedIds,
	onAllSelected,
}: {
	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void
	onAllSelected: (val: boolean) => void
}) => ({
	id: "checkbox",
	size: 30,
	header: ({ table }: { table: Table<any> }) => (
		<TableCheckbox
			isHeader
			isChecked={table.getIsAllRowsSelected()}
			isPartialChecked={table.getIsSomeRowsSelected()}
			onChange={() => {
				table.toggleAllRowsSelected()
				table.toggleAllRowsExpanded(true)

				if (!table.getIsAllRowsSelected()) {
					const allIds = table
						.getCoreRowModel()
						.rows.map((el: Row<any>) => el.original.id as string)
					setSelectedIds(allIds)
					onAllSelected(true)
				} else {
					setSelectedIds([])
					onAllSelected(false)
				}
			}}
		/>
	),
	cell: ({ row, table }: { table: Table<any>; row: Row<any> }) => (
		<TableCheckbox
			isChecked={row.getIsSelected()}
			onChange={(e: MouseEvent) => {
				e.preventDefault()
				e.stopPropagation()

				row.toggleSelected()

				if (row.getIsSelected()) {
					setSelectedIds(selectedIds.filter((id: string) => id !== row.original.id))
				} else {
					setSelectedIds([...selectedIds, row.original.id as string])
				}

				// Wait one tick for the row to update
				setTimeout(() => {
					onAllSelected(table.getIsAllRowsSelected())
				}, 0)
			}}
		/>
	),
})

type Props = {
	headers: Header[]
	data: { [k: string]: any }[]
	t: (key: string, options?: TOptions | undefined) => string
	useCheckboxes: boolean
	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void
	onAllSelected?: (val: boolean) => void
	useActions?: boolean
	onEdit?: (row: any) => void
	onDelete?: (id: string) => void
	useExpand?: boolean
}

export const getColumns = ({
	headers,
	data,
	t,
	selectedIds,
	setSelectedIds,
	useCheckboxes,
	onAllSelected = () => {},
	useActions,
	onEdit,
	onDelete,
	useExpand,
}: Props): ColumnDef<any>[] => {
	return [
		...(useExpand ? [getExpandColumn()] : []),
		...(useCheckboxes
			? [
					getCheckboxColumn({
						selectedIds,
						setSelectedIds,
						onAllSelected,
					}),
				]
			: []),
		...headers.map(
			({
				key,
				title,
				extraPopupText,
				translate = true,
				defaultSize = 100,
				maxSize,
				sortable,
				rightAlign,
				renderFunction,
			}) =>
				({
					accessorKey: key,
					accessorFn: event => get(event, key, undefined),
					size: defaultSize,
					maxSize,
					rightAlign,
					enableSorting: sortable,
					header: () => (translate ? t(title) : title),
					extraPopupText,
					cell: ({ cell, table, row }) => {
						const render =
							renderFunction ||
							(cellRenderFunctions[key] as TableCellRenderFunctionsType[string] | undefined)
						return render ? render({ cell, t, data, table, row }) : cell?.getValue() || null
					},
					sortingFn: tableSortFn,
				}) as ColumnDef<any>
		),
		...(useActions && onEdit && onDelete
			? [
					{
						id: "actions",
						size: 50,
						header: () => <></>,
						cell: ({ row: { original: row } }: { row: Row<any> }) => (
							<TableActions row={row} onEdit={onEdit} onDelete={onDelete} />
						),
					},
				]
			: []),
	]
}
