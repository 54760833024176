import { Outlet, Route, Routes, useLocation, useParams, Navigate } from "react-router-dom"

import Sidebar from "components/Sidebar"
import { Home } from "pages/home/home"
import TenantDashboard from "pages/customer/dashboard"
import TenantDetails from "pages/CustomerDetails/customerDetails"
import TenantWasteTypes from "pages/tenantWasteTypes/tenantWasteTypes"
import HeaderBar from "UI/Header"
import { Loading } from "./App"
import "./Main.css"
import CircularityOverview from "pages/circularity/overview"
import Reporting from "pages/reporting"
import ManageInfrastructure from "pages/infrastructure/manage"
import { CostForecasts } from "pages/circularity/costs/costForecast"
import { Events } from "pages/events/events"
import NotFound from "pages/error/NotFound"
import Unauthorized from "pages/error/Unauthorized"
import ManageCustomers from "pages/customer/manage"
import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { Terminal, useTerminalsState, ClientType } from "States/Terminals"
import ErrorNotice from "components/ErrorNotice"
import { getPermittedTerminals, hasAnyTerminalAccess, isCarrotAdmin } from "Utils/authUtils"
import { Configuration } from "pages/configuration"
import { timezoneStore, useConfig } from "api/hooks/useConfig"
import { translationContext } from "States/translationContext"
import { Allocations } from "pages/allocations/allocations"
import PrintQRCodesPage from "pages/qr/PrintQRCodesPage"
import { trpc } from "Utils/trpc"
import { Logout } from "pages/logout"
import { ImportInfrastructure } from "pages/infrastructure/import"
import { ImportTenants } from "pages/customer/manage/components/import"
import { GlobalSettings } from "pages/globalSettings"
import ClientWrapper from "ClientWrapper"
import { useSelectedSite } from "api/hooks/useSelectedSite"
import { isMultiTenant } from "Configs/config"
import { ModalProvider } from "./Contexts/Modal"
import { useSites } from "api/hooks/useSites"

const LoadingIndicator = () => {
	return (
		<svg
			className="active"
			viewBox="0 0 100 1"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line x1="0" y1="0" x2="100" y2="0" />
		</svg>
	)
}

const MWM_ONLY_ROUTES = ["infrastructure/manage/import"]

const RE_ONLY_ROUTES = [
	"tenant/dashboard",
	"tenant/details",
	"tenant/waste-streams",
	"settings",
	"reporting",
	"circularity/overview",
	"circularity/costs",
]

const getRoutes = (isMWM: boolean, config: any, isAdmin: boolean) => {
	return [
		{
			path: "",
			element: <Home />,
		},
		{
			path: "reporting",
			element: <Reporting />,
		},
		{
			path: "events",
			element: <Events />,
		},
		{
			path: "tenant/dashboard",
			element: <TenantDashboard />,
		},
		{
			path: "tenant/details",
			element: <TenantDetails />,
			redirects: ["/tenant/dashboard/details"],
		},
		{
			path: "tenant/waste-streams",
			element: <TenantWasteTypes />,
			redirects: ["/tenant/waste-types", "/tenant/dashboard/waste-types"],
		},
		{
			path: "circularity/overview",
			element: <CircularityOverview />,
		},
		{
			path: "circularity/costs",
			element: <CostForecasts />,
		},
		{
			path: "customer/manage/import",
			element: <ImportTenants />,
		},
		{
			path: "customer/manage/:id?",
			element: <ManageCustomers />,
		},
		{
			path: "infrastructure/manage/import",
			element: <ImportInfrastructure />,
		},
		{
			path: "infrastructure/manage/:terminalId?/:accessParentId?/:containerId?",
			element: <ManageInfrastructure />,
		},
		{
			path: "settings",
			element: <Configuration />,
		},
		{
			path: "global-settings",
			element: <GlobalSettings />,
		},
		{
			path: "infrastructure/manage/qr-codes",
			element: <PrintQRCodesPage />,
		},
		{
			path: "allocations/:id?",
			element: <Allocations />,
		},
		{
			path: "logout",
			element: <Logout />,
		},
		{
			path: "*",
			element: <NotFound />,
		},
	].filter(route => {
		if (MWM_ONLY_ROUTES.includes(route.path)) {
			return isMWM
		}

		if (RE_ONLY_ROUTES.includes(route.path)) {
			return !isMWM
		}
		if (route.path === "allocations/:id?") {
			return isMWM && config?.useExternalCRM
		}
		if (route.path === "global-settings") {
			return isAdmin
		}
		// All other routes are available to all
		return true
	})
}

/**
 *
 * A component that wraps the selected component and provides navigation.
 * This component needs to be within the router to get the client from the url.
 *
 */
const MainLayout = ({
	children,
	showSidebar,
	toggleMenu,
	error,
}: {
	children: ReactNode
	showSidebar: boolean
	toggleMenu: () => void
	error: any
}) => {
	const { client } = useParams()
	const { selectedSite, setSelectedSite } = useSelectedSite()
	const { isMWM } = useConfig()

	const { sites } = useSites()

	useEffect(() => {
		if (isMWM || !isMultiTenant) {
			setSelectedSite(undefined)
		} else if (client !== selectedSite?.siteName) {
			const foundSite = sites?.find(s => s.siteName === client)
			if (foundSite) {
				setSelectedSite(foundSite)
			}
		}
	}, [client, selectedSite, setSelectedSite, sites, isMWM])

	const multiTenantSiteNotFound = useMemo(
		() => isMultiTenant && client && sites && !sites?.find(s => s.siteName === client),
		[client, sites]
	)

	if (multiTenantSiteNotFound) {
		return <NotFound />
	}

	return (
		<ModalProvider>
			<div id="Main" className="grid md:grid-cols-main">
				<LoadingIndicator />
				<Sidebar showSidebar={showSidebar} toggleMenu={toggleMenu} />
				<main className="grid min-h-screen grid-cols-1 grid-rows-main bg-grey1 transition duration-200 ease-in-out print:min-h-0">
					<HeaderBar toggleMenu={toggleMenu} sidebarOpen={showSidebar} />
					{!!error && <ErrorNotice />}
					{!error && <div className="row-start-2">{children}</div>}
				</main>
			</div>
		</ModalProvider>
	)
}

const Main: React.FC = () => {
	const { currentTerminal, setTerminalState } = useTerminalsState()
	const { config, isConfigLoading, isRealEstate, isMWM, useClientPath } = useConfig()
	const { setContext } = translationContext()
	const location = useLocation()
	const { timezone } = timezoneStore()
	const isAdmin = isCarrotAdmin()
	const { siteName } = useSelectedSite()
	const [isSettingTerminal, setIsSettingTerminal] = useState(isRealEstate)

	const {
		data,
		isLoading: loading,
		error,
	} = trpc.terminals.getAll.useQuery(undefined, {
		queryKeyHashFn: () => ["getAllTerminals", siteName].toString(),
	})

	const terminalId = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const terminalIdFromUrl = urlParams.get("terminal-id")
		const terminalInStorage = localStorage.getItem("terminalId")
		if (terminalIdFromUrl) {
			localStorage.setItem("terminalId", terminalIdFromUrl)
			return terminalIdFromUrl
		} else if (terminalInStorage) {
			return terminalInStorage
		}
	}, [])

	useEffect(() => {
		if (isRealEstate) {
			const { terminals, allTerminals } = getPermittedTerminals(data, isMWM)
			const filteredTerminalIds = terminals.map(t => t.id)

			let newTerminal = undefined

			if (currentTerminal?.id && filteredTerminalIds.includes(currentTerminal.id)) {
				newTerminal = currentTerminal
			} else if (terminals?.length === 1) {
				newTerminal = terminals[0]
			} else {
				newTerminal = terminals?.find((re: Terminal) => re.id === terminalId) ?? terminals?.[0]
			}

			if (newTerminal) {
				setTerminalState({
					type: ClientType.REAL_ESTATE,
					currentTerminal: newTerminal,
					terminals,
					allTerminals,
				})
			}
			setIsSettingTerminal(false)
		} else {
			const { terminals, allTerminals } = getPermittedTerminals(data, isMWM)
			setTerminalState({
				terminals,
				allTerminals,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		config?.type && setContext(config?.type)
	}, [config?.type, setContext])

	const [showSidebar, setShowSidebar] = useState(false)

	useEffect(() => {
		setShowSidebar(false) // close sidebar on route change
	}, [location])

	const toggleMenu = () => {
		setShowSidebar(!showSidebar)
	}

	if (
		!error &&
		(loading ||
			isConfigLoading ||
			(hasAnyTerminalAccess() && isSettingTerminal) ||
			!timezone ||
			(isRealEstate && !currentTerminal))
	) {
		console.log("Waiting for terminals/config to load")
		return <Loading />
	}

	if (error?.message.includes("401")) return <Unauthorized />

	if (error) {
		return <ErrorNotice />
	}

	return (
		<Routes>
			{/* Support for existing routes. Redirects to routes nested within the selected client */}
			<Route
				element={
					<MainLayout showSidebar={showSidebar} toggleMenu={toggleMenu} error={error}>
						{useClientPath ? <ClientWrapper /> : <Outlet />}
					</MainLayout>
				}
			>
				{getRoutes(isMWM, config, isAdmin).map(route => (
					<React.Fragment key={route.path}>
						{route.redirects?.map(redirect => (
							<Route
								key={redirect}
								path={redirect}
								element={<Navigate replace to={`/${route.path}`} />}
							/>
						))}
						<Route key={route.path} path={route.path} element={route.element} />
					</React.Fragment>
				))}
			</Route>

			{/* The routes that are actually used after redirect */}
			{useClientPath && (
				<Route
					path="/:client/*"
					element={
						<MainLayout showSidebar={showSidebar} toggleMenu={toggleMenu} error={error}>
							<Outlet />
						</MainLayout>
					}
				>
					{getRoutes(isMWM, config, isAdmin).map(route => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
				</Route>
			)}
		</Routes>
	)
}

export default Main
