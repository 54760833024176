import { Terminal as ApiTerminal } from "admin-client-server/src/coreApi/terminals/types"
import create from "zustand"
import { combine } from "zustand/middleware"

export type Terminal = ApiTerminal[][number] & {
	accessToken: string
	fractionDiscriminator?: string
	children?: { id: string }[]
}

export enum ClientType {
	REAL_ESTATE,
	MWM,
}

type TerminalState =
	| {
			type: ClientType.REAL_ESTATE
			allTerminals: Terminal[]
			terminals: Terminal[]
			currentTerminal: Terminal
	  }
	| {
			type: ClientType.MWM
			allTerminals: Terminal[]
			terminals: Terminal[]
			currentTerminal: undefined
	  }
	| {
			type: ClientType | undefined
			terminals: Terminal[]
			allTerminals: Terminal[]
			currentTerminal: Terminal | undefined
	  }

export const useTerminalsState = create(
	combine(
		{
			type: undefined,
			/** NB! This is only used for RE. For MWMs this is always undefined */
			currentTerminal: undefined,
			/** List of active terminals */
			terminals: [],
			/** List of all terminals, including inactive ones. Used in f.ex. Settings */
			allTerminals: [],
		} as TerminalState,
		(set, get) => ({
			setCurrentTerminal: (terminal: Terminal) => {
				if (terminal?.id) localStorage.setItem("terminalId", terminal.id)
				set(prevState => ({ ...prevState, currentTerminal: terminal }))
			},
			setTerminalState: (state: Partial<ReturnType<typeof get>>) => {
				if (state?.currentTerminal?.id)
					localStorage.setItem("terminalId", state?.currentTerminal?.id)
				return set(prevState => ({ ...prevState, ...state }))
			},
		})
	)
)

const isRETerminalState = (
	state: Partial<TerminalState>
): state is Extract<TerminalState, { type: ClientType.REAL_ESTATE }> => {
	return state.type === ClientType.REAL_ESTATE
}

/** NB! Will throw an error if used in MWM context. Use this hook for asserting that the current terminal is defined */
export const useTerminalsStateRE = () => {
	const terminalState = useTerminalsState()

	if (!isRETerminalState(terminalState)) {
		throw new Error("Use useTerminalsStateRE only in Real Estate context")
	}
	return terminalState
}
