import { createContext, useContext, useState, ReactNode, useMemo, useCallback } from "react"
import { isMultiTenant, siteName as configSiteName } from "Configs/config"
import { Site as SelectedSite } from "admin-client-server/src/sites/index"

type SiteContextType = {
	siteName: string | undefined
	selectedSite: SelectedSite | undefined
	setSelectedSite: (site?: SelectedSite) => void
}

// Context and Provider
const SiteContext = createContext<SiteContextType | undefined>(undefined)

function safeJsonParse(str: string) {
	try {
		return JSON.parse(str)
	} catch (error) {
		return undefined
	}
}

const initialSite = safeJsonParse(localStorage.getItem("selectedSite") ?? "") as
	| SelectedSite
	| undefined

export const SiteProvider = ({ children }: { children: ReactNode }) => {
	const [selectedSite, _setSelectedSite] = useState(initialSite)

	const setSelectedSite = useCallback((site?: SelectedSite) => {
		localStorage.setItem("selectedSite", JSON.stringify(site))
		_setSelectedSite(site)
	}, [])

	const siteName = useMemo(() => {
		if (isMultiTenant) {
			return selectedSite?.siteName || undefined
		}
		return configSiteName
	}, [selectedSite])

	const context = useMemo(
		() => ({
			selectedSite,
			siteName,
			setSelectedSite,
		}),
		[selectedSite, setSelectedSite, siteName]
	)

	return <SiteContext.Provider value={context}>{children}</SiteContext.Provider>
}

/**
 *
 * @returns {SiteContextType}
 * - siteName: string | undefined - the name of the site, either from the selected site or from environment config
 * - selectedSite: Site | undefined - the site selected by the user
 * - setSelectedSite: (site: Site) => void - function to set the selected site
 */
export const useSelectedSite = (): SiteContextType => {
	const context = useContext(SiteContext)
	if (!context) {
		throw new Error("useSites must be used within an SiteProvider")
	}
	return context
}
