import { useTerminals } from "api/hooks/useTerminals"
import { useConfig } from "api/hooks/useConfig"
import { Page } from "components/Page"
import MultiListTable from "components/pageCards/multiListTable"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { useMultiListInitialState } from "components/pageCards/multiListTable/multiListState"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useModal } from "Contexts"
import { first, uniqBy } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { AccessParentContainerData } from "../functions"
import { CreateTerminalModal } from "../terminals/CreateTerminalModal"
import { CreateBuildingModal } from "../terminals/CreateBuildingModal"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { AddContainerModal } from "../modals/addContainerModal"
import { FilterSort, FilterSortMenuType, Option } from "components/pageCards/filterSort/types"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { OPTION_ALL, SORTING_KEY, applyFilters } from "components/pageCards/filterSort/constants"
import { NoAccessParentsForContainersModal } from "pages/customer/manage/components/NoAccessParentsForContainers"
import { useAccessPoints } from "./useAccessPoints"
import { useAccessParentsWithPoints } from "./useAccessParentsWithPoints"
import { accessParentsWithFillLevel } from "Utils/accessParentsWithFillLevel"
import { AccessParentWithChildren } from "admin-client-server/src/coreApi/accessParents/types"
import { useConfigService } from "pages/configuration/useConfigService"
import getTerminalSection from "./sections/getTerminalSection"
import getAccessParentSection from "./sections/getAccessParentSection"
import getAccessParentVariantsSection from "./sections/getAccessParentVariantsSection"
import getContainerSection from "./sections/getContainerSection"
import { isCarrotAdmin } from "Utils/authUtils"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"
import { useNavigate } from "Utils/useNavigate"

export const ManageInfrastructure = () => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const navigate = useNavigate()
	const { isMWM, config, isConfigLoading } = useConfig()
	const { wasteTypeConfig } = useConfigService()

	const { isLoadingTerminals } = useTerminals()
	const { setSelectedContainers, selectedContainers } = useMultiSelectContext()
	const [selectedDepotId, setSelectedDepotId] = useState<string>()
	const {
		allTerminals,
		terminals: activeTerminals,
		currentTerminal,
		setCurrentTerminal,
	} = useTerminalsState()

	// MWMs should be able to see inactive terminals
	const terminals = isMWM ? allTerminals : activeTerminals

	const {
		terminalId: urlTerminalId,
		accessParentId: urlAccessParentId,
		containerId: urlContainerId,
	} = useParams<{
		terminalId?: string
		accessParentId?: string
		containerId?: string
	}>()

	const terminalId = useMemo(
		() => urlTerminalId || currentTerminal?.id || first(terminals)?.id || "",
		[urlTerminalId, terminals, currentTerminal]
	)

	const { accessParents, isLoading } = useAccessParentsWithPoints(terminalId)

	const depotsEnabled = useMemo(() => {
		return config?.useWasteSuctionSystem
	}, [config])

	const [terminalsFilterState, setTerminalsFilterState] = useState<ActiveOptions<"sorting">>(
		{} as ActiveOptions<any>
	)

	const [accessParentsFilterState, setAccessParentsFilterState] = useState<
		ActiveOptions<"status" | "sorting">
	>({} as ActiveOptions<any>)

	const [accessParentVarientsFilterState, setAccessParentVariantsFilterState] = useState<
		ActiveOptions<"status" | "sorting">
	>({} as ActiveOptions<any>)

	const [containersFilterState, setContainersFilterState] = useState<
		ActiveOptions<"status" | "wasteCode" | "sorting">
	>({} as ActiveOptions<any>)

	const resetSelectedEntities = useCallback(() => {
		setSelectedContainers([])
		setSelectedDepotId(undefined)
	}, [setSelectedContainers])

	useEffect(() => {
		resetSelectedEntities()
	}, [resetSelectedEntities])

	useEffect(() => {
		const terminal = terminals.find((el: any) => el.id === urlTerminalId)

		if (!isMWM && terminal) {
			setCurrentTerminal(terminal)
		}
	}, [urlTerminalId, terminals, setCurrentTerminal, isMWM, navigate])

	useEffect(() => {
		if (isMWM && !urlTerminalId && terminals.length) {
			navigate(getInfrastructureUrl({ terminalId: terminals[0].id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- navigate is omitted on purpose
	}, [isMWM, terminals])

	const accessParentId = useMemo(
		() =>
			accessParents.find(({ id }) => id === urlAccessParentId)?.id ||
			(!isCarrotAdmin() && first(accessParents)?.id) ||
			"",
		[urlAccessParentId, accessParents]
	)

	const {
		containers: unfilteredContainers,
		depots: unfilteredDepots,
		isLoading: isLoadingAccessParentChildren,
		getDepotContainers,
	} = useAccessPoints({
		accessParentId,
	})

	const terminalList = useMemo(() => {
		const filteredTerminals = applyFilters(terminals, terminalsFilterState)

		return filteredTerminals
	}, [terminals, terminalsFilterState])

	const accessParentsList = useMemo(() => {
		const terminalAccessParents = accessParents.filter(({ parentId }) => parentId === terminalId)
		const filteredAccessParents = applyFilters(terminalAccessParents, accessParentsFilterState)

		return accessParentsWithFillLevel(filteredAccessParents)
	}, [accessParents, accessParentsFilterState, terminalId])

	const depots = useMemo(() => {
		const depots = unfilteredDepots || []
		const filteredDepots = applyFilters(depots, accessParentVarientsFilterState)

		return filteredDepots
	}, [accessParentVarientsFilterState, unfilteredDepots])

	const containers = useMemo(() => {
		const accessParentContainers =
			(!!selectedDepotId && getDepotContainers(selectedDepotId)) || unfilteredContainers || []
		const filteredContainers: AccessParentContainerData[] = applyFilters(
			accessParentContainers,
			containersFilterState
		)

		return filteredContainers
	}, [selectedDepotId, getDepotContainers, unfilteredContainers, containersFilterState])

	useEffect(() => {
		if (urlContainerId && !selectedContainers.length) {
			const container = containers.find(el => el.id === urlContainerId)

			if (container) {
				setSelectedContainers([
					{
						id: container.id || "",
						name: container.name,
						externalId: container.externalId,
					},
				])
			}
		}
	}, [urlContainerId, selectedContainers, containers, setSelectedContainers])

	const containerId = useMemo(() => urlContainerId || "", [urlContainerId])

	const defaultWasteTypeClassificationSystemId =
		currentTerminal?.fractionDiscriminator || config?.wasteTypeClassificationSystemId || ""

	const addContainer = useCallback(() => {
		if (accessParentId) {
			showModal(
				<AddContainerModal
					{...{ accessParentId, selectedDepotId, defaultWasteTypeClassificationSystemId }}
				/>
			)
		} else {
			showModal(<NoAccessParentsForContainersModal />)
		}
	}, [accessParentId, selectedDepotId, showModal, defaultWasteTypeClassificationSystemId])

	const createTerminal = useCallback(() => {
		showModal(isMWM ? <CreateTerminalModal /> : <CreateBuildingModal />)
	}, [showModal, isMWM])

	const navigateToTerminal = useCallback(
		(id: string) => {
			const url = getInfrastructureUrl({ terminalId: id })

			navigate(url)
		},
		[navigate]
	)

	const navigateToAccessParent = useCallback(
		(id: string) => {
			const url = getInfrastructureUrl({
				terminalId,
				accessParentId: id,
			})

			navigate(url)
		},
		[terminalId, navigate]
	)

	const navigateToContainer = useCallback(
		(id: string) => {
			const url = getInfrastructureUrl({
				terminalId,
				accessParentId,
				containerId: id,
			})

			navigate(url)
		},
		[terminalId, accessParentId, navigate]
	)

	const nameSortingOptions = useMemo(
		() => [
			{
				option: t("filterLabels:nameAscending"),
				value: "name|asc",
			},
			{
				option: t("filterLabels:nameDescending"),
				value: "name|desc",
			},
		],
		[t]
	)

	const nrContainersSortingOptions = useMemo(
		() => [
			{
				option: t("filterLabels:nrContainersAscending"),
				value: "containers.length|asc",
			},
			{
				option: t("filterLabels:nrContainersDescending"),
				value: "containers.length|desc",
			},
		],
		[t]
	)

	const fillLevelSortingOptions = useMemo(
		() =>
			isMWM
				? [
						{
							option: t("filterLabels:fillLevelAscending"),
							value: "fillLevel|asc",
						},
						{
							option: t("filterLabels:fillLevelDescending"),
							value: "fillLevel|desc",
						},
					]
				: [],
		[t, isMWM]
	)

	const getSorting = useCallback(
		(options: Option[]): FilterSort => ({
			type: "sort",
			menuType: FilterSortMenuType.Select,
			options,
			title: "filterLabels:sortBy",
			id: SORTING_KEY,
			defaultValue: options[0].value,
		}),
		[]
	)

	const terminalsSection: MultiListSectionConfig<typeof terminals> | null = useMemo(
		() =>
			getTerminalSection({
				isMWM,
				createTerminal,
				terminalList,
				isLoadingTerminals,
				terminalId,
				onRowClick: terminal => {
					resetSelectedEntities()
					terminal.id && navigateToTerminal(terminal.id)
				},
				filters: [getSorting(nameSortingOptions)],
				setTerminalsFilterState,
			}),
		[
			isMWM,
			createTerminal,
			terminalList,
			isLoadingTerminals,
			terminalId,
			resetSelectedEntities,
			navigateToTerminal,
			getSorting,
			nameSortingOptions,
			setTerminalsFilterState,
		]
	)

	const statusFilter: FilterSort = useMemo(
		() => ({
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: [
				{
					option: `${t("formLabels:status")}: ${t("genericLabels:all")}`,
					value: "all",
				},
				...POINT_STATUSES.map(value => ({
					option: `${t("formLabels:status")}: ${t(`status:${value}`)}`,
					value,
				})),
			],
			title: "formLabels:status",
			id: "status",
			defaultValue: OPTION_ALL,
		}),
		[t]
	)

	const wasteTypes = useMemo(
		() =>
			uniqBy(
				(containers || []).map(c => ({
					option:
						`${
							wasteTypeConfig.find(wtc => wtc.wasteTypeCode === c.wasteCode)?.name || c.wasteType
						} (${c.wasteCode})` || c.wasteCode,
					value: c.wasteCode,
				})),
				"value"
			),
		[containers, wasteTypeConfig]
	)

	const wasteTypesFilter: FilterSort = useMemo(
		() => ({
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: [
				{
					option: t("filterLabels:allWasteTypes"),
					value: "all",
				},
				...wasteTypes,
			],
			title: "genericLabels:wasteType",
			id: "wasteCode",
			defaultValue: OPTION_ALL,
		}),
		[t, wasteTypes]
	)

	const getAccessParentListEntryBottomLabel = useCallback(
		(entry: AccessParentWithChildren) => {
			const containerEntity =
				entry.containers?.length === 1
					? t("entities:container").toLowerCase()
					: t("entities:containers").toLowerCase()
			if (depotsEnabled && entry.depots?.length) {
				const depotEntity =
					entry.depots.length === 1
						? t("entities:depot").toLowerCase()
						: t("entities:depots").toLowerCase()
				return t("propertyLabels:depotAndContainerCount", {
					depotCount: entry.depots.length,
					depotEntity,
					containerCount: entry.containers?.length ?? 0,
					containerEntity,
				})
			}
			return t("propertyLabels:containerCount", {
				containerCount: entry.containers?.length ?? 0,
				containerEntity,
			})
		},
		[t, depotsEnabled]
	)

	const accessParentsSection: MultiListSectionConfig<typeof accessParentsList> = useMemo(
		() =>
			getAccessParentSection({
				isMWM,
				t,
				accessParentsList,
				qrLinkDisabled: !terminals.length || !accessParents.length,
				showModal,
				isLoading,
				accessParentId,
				getAccessParentListEntryBottomLabel,
				onRowClick: ({ id }) => {
					resetSelectedEntities()
					id && navigateToAccessParent(id)
				},
				filters: [
					statusFilter,
					getSorting([
						...nameSortingOptions,
						...nrContainersSortingOptions,
						...fillLevelSortingOptions,
					]),
				],
				setAccessParentsFilterState,
			}),
		[
			isMWM,
			t,
			accessParentsList,
			terminals,
			accessParents,
			showModal,
			isLoading,
			accessParentId,
			getAccessParentListEntryBottomLabel,
			resetSelectedEntities,
			navigateToAccessParent,
			statusFilter,
			getSorting,
			nameSortingOptions,
			nrContainersSortingOptions,
			fillLevelSortingOptions,
			setAccessParentsFilterState,
		]
	)

	const accessParentVariantsSection: MultiListSectionConfig<typeof depots> = useMemo(
		() =>
			getAccessParentVariantsSection({
				depots,
				isLoadingAccessParentChildren,
				selectedDepotId,
				t,
				onRowClick: ({ id }) => {
					setSelectedContainers([])
					setSelectedDepotId(id)
					accessParentId && navigateToAccessParent(accessParentId)
				},
				accessParentId,
				filters: [
					statusFilter,
					getSorting([
						...nameSortingOptions,
						...nrContainersSortingOptions,
						...fillLevelSortingOptions,
					]),
				],
				setAccessParentVariantsFilterState,
				onLinkButtonClick: () => {
					resetSelectedEntities()
					accessParentId && navigateToAccessParent(accessParentId)
				},
				showModal,
				setSelectedDepotId,
			}),
		[
			depots,
			isLoadingAccessParentChildren,
			selectedDepotId,
			accessParentId,
			statusFilter,
			getSorting,
			nameSortingOptions,
			nrContainersSortingOptions,
			fillLevelSortingOptions,
			t,
			setSelectedContainers,
			navigateToAccessParent,
			resetSelectedEntities,
			showModal,
			setSelectedDepotId,
			setAccessParentVariantsFilterState,
		]
	)

	const containersSection: MultiListSectionConfig<typeof containers> = useMemo(
		() =>
			getContainerSection({
				isConfigLoading,
				addContainer,
				containers,
				isLoadingAccessParentChildren,
				selectedContainers,
				wasteTypeConfig,
				t,
				onRowClick: ({ id }) => id && navigateToContainer(id),
				onMultipleSelect: entries => {
					setSelectedContainers(
						entries.map(el => ({ id: el.id || "", name: el.name, externalId: el.externalId }))
					)
					if (urlAccessParentId && entries.length !== 1) {
						navigateToAccessParent(urlAccessParentId)
					}

					if (entries.length === 1 && entries[0].id) {
						navigateToContainer(entries[0].id)
					}
				},
				accessParentId,
				containerId,
				selectedDepotId,
				filters: [
					statusFilter,
					wasteTypesFilter,
					getSorting([...nameSortingOptions, ...fillLevelSortingOptions]),
				],
				setContainersFilterState,
				isMWM,
			}),
		[
			addContainer,
			isConfigLoading,
			containers,
			isLoadingAccessParentChildren,
			accessParentId,
			containerId,
			selectedDepotId,
			statusFilter,
			wasteTypesFilter,
			getSorting,
			nameSortingOptions,
			fillLevelSortingOptions,
			selectedContainers,
			t,
			navigateToContainer,
			setSelectedContainers,
			urlAccessParentId,
			navigateToAccessParent,
			wasteTypeConfig,
			isMWM,
		]
	)

	const tableSections = useMemo(() => {
		const sections: any[] = [
			accessParentsSection,
			depotsEnabled && accessParentVariantsSection,
			containersSection,
		].filter(Boolean)

		if (isCarrotAdmin() || isMWM) {
			sections.unshift(terminalsSection)
		}

		return sections
	}, [
		accessParentsSection,
		depotsEnabled,
		accessParentVariantsSection,
		containersSection,
		terminalsSection,
		isMWM,
	])

	const initialOpenDrawer = useMemo(() => {
		if (urlContainerId || selectedContainers.length > 1) {
			return isCarrotAdmin() ? (depotsEnabled ? 3 : 2) : 1
		}

		if (urlAccessParentId) {
			return isCarrotAdmin() ? (depotsEnabled ? 2 : 1) : 0
		}

		return 0
	}, [urlContainerId, urlAccessParentId, selectedContainers, depotsEnabled])

	const initialOpenSection = useMemo(() => {
		if (urlAccessParentId || urlContainerId || selectedContainers.length > 1) {
			return isCarrotAdmin() ? (depotsEnabled ? 2 : 1) : 0
		}

		return 0
	}, [urlContainerId, urlAccessParentId, selectedContainers, depotsEnabled])

	const initialState: useMultiListInitialState = useMemo(
		() => ({
			selectedDrawerIndex: initialOpenDrawer,
			selectedSectionIndex: initialOpenSection,
		}),
		[initialOpenSection, initialOpenDrawer]
	)

	const goToImport = useCallback(() => {
		navigate("/infrastructure/manage/import")
	}, [navigate])

	return (
		<Page
			fullHeight
			title="sidebarLabels:manageInfrastructure"
			button={
				isMWM
					? {
							label: "importLabels:importInfrastructure",
							onClick: goToImport,
							type: "button",
							color: "primary",
						}
					: undefined
			}
		>
			<MultiListTable {...{ sections: tableSections, initialState }} />
		</Page>
	)
}

export default ManageInfrastructure
