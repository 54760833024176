import { Button } from "components/button/index"
import TrashIcon from "Icons/Trash"
import { FC } from "react"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { CreateTenantFormValues, CustomerContactPerson } from "../../types"
import FormInput from "components/Form/FormInput"
import { EMAIL_REGEX } from "Utils/isValidEmail"
import { isValidPhoneNumberAllowEmpty } from "Utils/isValidPhoneNumber"
import PhoneInput from "components/Form/PhoneInput"

const defaultContactPerson: CustomerContactPerson = {
	name: "",
	email: "",
	phone: "",
}

interface TenantContactInfoFormProps {
	formHandler: UseFormReturn<CreateTenantFormValues>
	useNumberForHint?: boolean
}

export const TenantContactInfoFormSection: FC<TenantContactInfoFormProps> = ({
	formHandler,
	useNumberForHint = true,
}) => {
	const { t } = useTrans()
	const {
		register,
		control,
		formState: { errors },
	} = formHandler
	const {
		fields: contactPeople,
		append,
		remove: removeRow,
	} = useFieldArray({
		control,
		name: "contactPeople",
	})

	const addRow = () => append(defaultContactPerson, { shouldFocus: false })

	return (
		<div className="md:ml-3">
			<h2 className="mb-2 font-dmSans text-base font-medium">{`${useNumberForHint ? "2. " : ""}${t(
				"hints:tenantContactInfo"
			)}`}</h2>
			<p className="mb-2 font-dmSans text-xs font-normal text-black">
				{t("hints:tenantContactInfoDetails")}
			</p>
			{contactPeople.map((row, index) => {
				return (
					<div key={row.id} className={`flex ${index > 0 ? "mt-2" : ""}`}>
						<div className="mb-2 flex flex-1 flex-col space-y-2 2xl:flex-row 2xl:space-x-2 2xl:space-y-0">
							<FormInput
								name={`contactPeople.${index}.name`}
								label={t("formLabels:name")}
								required
								register={register}
								error={errors.contactPeople?.[index]?.name}
							/>
							<FormInput
								name={`contactPeople.${index}.email`}
								label={t("formLabels:email")}
								regexPattern={EMAIL_REGEX}
								register={register}
								required
								error={errors.contactPeople?.[index]?.email}
								patternError="errors:mustBeValidEmail"
							/>
							<PhoneInput
								name={`contactPeople.${index}.phone`}
								label={t("formLabels:phoneNumber")}
								validationFunction={isValidPhoneNumberAllowEmpty}
								minLength={7}
								control={control}
								error={errors.contactPeople?.[index]?.phone}
								patternError="errors:mustBeValidPhoneNumber"
							/>
						</div>
						<button
							onClick={() => removeRow(index)}
							className={"ml-3 mt-2 transition duration-300"}
							type="button"
							style={{ visibility: contactPeople.length > 1 ? "visible" : "hidden" }}
						>
							<TrashIcon />
						</button>
					</div>
				)
			})}
			<Button
				className="mt-2"
				label="actions:addRow"
				onClick={addRow}
				type="button"
				color="secondary"
			/>
		</div>
	)
}
