import { FilterTimeFrameValue } from "components/pageCards/filterSort/filterCreators"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { useAccessParents } from "pages/infrastructure/functions"
import { ChangeEvent, useEffect, useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsStateRE } from "States/Terminals"
import { getCurrentLocale } from "translations/functions/locale"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { formatNumberWithDecimalPlaces } from "Utils/formatFunctions"
import create from "zustand"
import { combine, persist } from "zustand/middleware"

export type TCostForecastHandler = ReturnType<typeof useCostForecast>["handleInputChange"]
export type TCostForecastData = ReturnType<typeof useCostForecast>["costForecastData"]

export const useCostForecast = () => {
	const {
		data: forecastStoreData,
		modified,
		setWasteForecast,
		setModified,
	} = useCostForecastStore()
	const { wasteTypes } = useCommonEntitiesStore()
	const { currentTerminal } = useTerminalsStateRE()

	const { data: dwhResponseData, isFetching } = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: getTimeFrame(FilterTimeFrameValue.YEAR_TO_DATE),
	})
	const { wasteTypes: facilityWasteTypes } = dwhResponseData?.terminal || {}
	const { accessParents: wasteRooms } = useAccessParents()
	const availableWasteCodes = wasteRooms?.reduce(
		(wasteCodes, { containers = [] }) => [
			...wasteCodes,
			...containers?.reduce(
				(roomWasteCodes, { wasteType: { code } }) =>
					wasteCodes.includes(code) || roomWasteCodes.includes(code)
						? roomWasteCodes
						: [...roomWasteCodes, code],
				[] as string[]
			),
		],
		[] as string[]
	)
	const isLoading = isFetching || !wasteTypes
	const costForecastData = useMemo(
		() =>
			availableWasteCodes?.map(wasteCode => ({
				wasteCode,
				wasteType: wasteTypes?.find(({ id }) => id === wasteCode)?.name,
				currentAmount:
					facilityWasteTypes?.find(({ code }) => code === wasteCode)?.weight.quantity || 0,
				...forecastStoreData[wasteCode],
			})),
		[availableWasteCodes, facilityWasteTypes, forecastStoreData, wasteTypes]
	)
	// Init values
	useEffect(() => {
		if (!isFetching)
			availableWasteCodes?.forEach(code => {
				const { currentPrice, futureAmount, futurePrice } = forecastStoreData[code] || {}
				const { weight } =
					facilityWasteTypes?.find(({ code: wasteType }) => wasteType === code) || {}
				const { quantity: currentAmount } = weight || {}
				setWasteForecast(code, {
					currentPrice: currentPrice ?? "",
					futureAmount: futureAmount ?? (formatNumberWithDecimalPlaces(currentAmount || 0, 0) || 0),
					futurePrice: futurePrice ?? "",
				})
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wasteTypes, isFetching, facilityWasteTypes, setWasteForecast])
	const handleInputChange =
		(wasteCode: string, key: keyof ICostForecastData[number]) =>
		(event: ChangeEvent<HTMLInputElement>) => {
			const currentForecast = forecastStoreData[wasteCode]
			const value = event.target.value || ""
			const locale = getCurrentLocale()
			const localeDecimalSeparator = new Intl.NumberFormat(locale).format(1.1).charAt(1)
			const parsedValue = value
				.trimStart() // trim leading spaces
				.replaceAll(/[^-+0-9,. ]/g, "") // replace all disallowed characters
				.replaceAll(/(?!^[-+])([-+])/g, "") // remove inappropriate + and -
				.replaceAll(",", localeDecimalSeparator === "," ? "." : "") // remove commas for locales where commas are thousand separators, or replace the decimal comma to a period for locales where commas are decimal separators
				.split(".") // remove more than 1 decimal points
				.reduce((prev, val, i, arr) => (arr.length > 1 && i === 0 ? val + "." : prev + val), "")
				.split(".") // remove leading and repeating pre decimal zeroes
				.map((val, i) =>
					i === 0
						? val.replaceAll(/^([+-]|)[0 ]+(?!$)/g, "$1").replaceAll(/^([+-]|)(\.)/g, "$10")
						: val
				)
				.join(".")
			setWasteForecast(wasteCode, { ...currentForecast, [key]: parsedValue })
			if (!modified) setModified(true)
		}
	return { costForecastData, isLoading, modified, setWasteForecast, handleInputChange }
}

interface ICostForecastData {
	[wasteCode: string]: {
		currentPrice: string
		futureAmount: string
		futurePrice: string
	}
}

const useCostForecastStore = create(
	persist(
		combine(
			{
				data: {} as ICostForecastData,
				modified: false,
			},
			set => ({
				setWasteForecast: (
					wasteCode: keyof ICostForecastData,
					forecastData: ICostForecastData[string]
				) =>
					set(({ data }) => ({
						data: { ...data, [wasteCode]: forecastData },
					})),
				setModified: (modified: boolean) => set({ modified }),
			})
		),
		{ name: "costForecast" }
	)
)
